import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
// @ts-expect-error
import { GALLERY_CONSTS } from 'pro-gallery-lib';
// @ts-expect-error
import { ProFullscreen, FULLSCREEN_EVENTS } from '@wix/pro-fullscreen-renderer';
// @ts-expect-error
import { ProGallery } from 'pro-gallery';

import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { st, classes } from './MediaGallery.st.css';
import '../Media/Media.global.css';
import { getGalleryStyles } from './MediaLayout';
import { IProGalleryItem } from '../../../../common/ContentEditor/plugins/pro-gallery/ProGalleryItem';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { Thumbnail } from '../Media/Thumbnail';
import { compose } from '../../../../common/utils/compose';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import { settingsParams } from '../../Settings/settingsParams';
import { withHostProps } from '../../contexts/AppSettings/withHostProps';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { IWixSiteColor } from '@wix/yoshi-flow-editor/tpa-settings';
import { MEDIA_GALLERY } from '../dataHooks';

interface MediaGalleryProps {
  items: IProGalleryItem[];
  fetchMore?(): void;
}

interface State {
  isInitializing: boolean;
  activeId: number;
}

type Props = MediaGalleryProps &
  WithSettingsProps &
  TPAComponentsConfig &
  IHostProps;

export class MediaGalleryComponent extends React.Component<Props, State> {
  readonly state: State = {
    isInitializing: true,
    activeId: -1,
  };
  containerRef = React.createRef<HTMLDivElement>();

  handleGalleryEvents = (eventName: string, data: any) => {
    switch (eventName) {
      case GALLERY_CONSTS.events.NEED_MORE_ITEMS:
        this.props.fetchMore?.();
        break;
      case GALLERY_CONSTS.events.ITEM_ACTION_TRIGGERED:
        this.setState({ activeId: data.idx });
        break;
      case GALLERY_CONSTS.events.ITEM_LOADED:
        this.setState({ isInitializing: false });
        break;
      case FULLSCREEN_EVENTS.CLOSE_FULLSCREEN:
        this.setState({ activeId: -1 });
        break;

      default:
    }
  };

  getThumbnail() {
    const { settings, mobile } = this.props;
    const showNameOnHoverKey = getSettingsKeyFor('showNameOnHover', mobile!);
    return settings.get(settingsParams[showNameOnHoverKey])
      ? Thumbnail
      : () => <div />;
  }

  getSiteColor(reference: string): string {
    const { style } = this.props;

    const color = style.siteColors.find(
      (siteColor: IWixSiteColor) => siteColor.reference === reference,
    );

    if (!color) {
      return undefined as any;
    }

    return color.value;
  }

  render() {
    const { items, mobile } = this.props;
    const { isInitializing, activeId } = this.state;
    const container = {
      width: 980,
      height: 100,
      avoidGallerySelfMeasure: true,
    };
    const fullscreenContainer = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    if (this.containerRef.current) {
      container.width = this.containerRef.current.clientWidth;
    }

    const galleryStyles = this.getGalleryStyles();

    return (
      <div
        ref={this.containerRef}
        className={st(classes.root, { mobile } as any)}
      >
        {isInitializing && <Text>Loading...</Text>}
        <ProGallery
          items={items}
          container={container}
          styles={galleryStyles}
          totalItemsCount={Infinity}
          eventsListener={this.handleGalleryEvents}
          customHoverRenderer={this.getThumbnail()}
          domId={MEDIA_GALLERY}
        />
        {activeId >= 0 && (
          <section className="pro-fullscreen-wrapper">
            <ProFullscreen
              items={items}
              initialIdx={activeId}
              itemsLoveData={[]}
              container={fullscreenContainer}
              totalItemsCount={Infinity}
              deviceType={mobile ? 'mobile' : 'desktop'}
              styles={galleryStyles}
              eventsListener={this.handleGalleryEvents}
            />
          </section>
        )}
      </div>
    );
  }

  private getGalleryStyles() {
    const { mobile, settings } = this.props;
    const {
      layoutSpacing,
      mobileLayoutSpacing,
      mediaLayout,
      imagesPerRow,
      mobileMediaLayout,
    } = settingsParams;
    const appSettings = {
      layoutSpacing: settings.get(layoutSpacing),
      mobileLayoutSpacing: settings.get(mobileLayoutSpacing),
      mediaLayout: settings.get(mediaLayout),
      imagesPerRow: settings.get(imagesPerRow),
      mobileMediaLayout: settings.get(mobileMediaLayout),
    };
    const colors = {
      bgColorExpand: this.getSiteColor('color-1'),
      actionsColorExpand: this.getSiteColor('color-5'),
    };
    const galleryStyles = getGalleryStyles(appSettings, colors, mobile!);
    return galleryStyles;
  }
}

export const MediaGallery = compose(
  withTPAConfig,
  withSettings,
  withHostProps,
)(MediaGalleryComponent);
MediaGallery.displayName = 'MediaGallery';
