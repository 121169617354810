import React from 'react';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';

import { RichContentEditor } from '../RichContent/lazy';
import { PRESETS } from '../RichContent/types';

import { classes, st } from './AboutEditor.st.css';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { ContentConverter } from '../../../../common/ContentEditor/content/ContentConverter';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { Container } from '../../../../common/components/Container/Container';
import { Block, BlockAlign } from '../../../../common/components/Block';
import { Button } from '../../../../common/components/Button';
import { Loader } from '../../../../common/components/Loader/Loader';

export interface AboutEditorProps {
  content: RawDraftContentState<any>;
  loading: boolean;
  mobile: boolean;

  saveChanges(contentString: string): void;

  cancel(): void;
}

interface AboutEditorState {
  contentState: RawDraftContentState<any>;
}

type Props = AboutEditorProps & WithTranslation;

export class AboutEditorComponent extends React.Component<
  Props,
  AboutEditorState
> {
  readonly state = {
    contentState: this.props.content,
  };

  onChange = (contentEditorState: any) => {
    this.setState({ contentState: contentEditorState });
  };

  onSave = () => {
    const { contentState } = this.state;
    this.props.saveChanges(JSON.stringify(contentState));
  };

  render() {
    const { mobile } = this.props;

    return mobile ? this.renderMobile() : this.renderDesktop();
  }

  isValid() {
    const { contentState } = this.state;
    return (
      contentState &&
      ContentConverter.parseTextFromRaw(contentState).length >= 20
    );
  }

  renderEditor(isMobile = false) {
    const { content } = this.props;

    return (
      <React.Suspense fallback={<Spinner />}>
        <RichContentEditor
          isMobile={isMobile}
          onChange={this.onChange}
          content={content}
          preset={PRESETS.ABOUT}
          usage="About"
        />
      </React.Suspense>
    );
  }

  renderMobile() {
    const { t, loading, cancel } = this.props;

    return (
      <div className={st(classes.root, { mobile: true })}>
        <div className={classes.mobileHeader}>
          <TextButton
            onClick={cancel}
            priority={TEXT_BUTTON_PRIORITY.secondary}
          >
            {t('groups-web.cancel')}
          </TextButton>
          <Tooltip
            content={t('groups-web.about.edit.tip')}
            placement="bottom-end"
          >
            <TextButton
              onClick={this.onSave}
              priority={TEXT_BUTTON_PRIORITY.primary}
              disabled={loading || !this.isValid()}
            >
              {t('groups-web.save')}
            </TextButton>
          </Tooltip>
        </div>
        {this.renderEditor(true)}
      </div>
    );
  }

  renderDesktop() {
    const { t, loading, cancel } = this.props;
    const isValid = this.isValid();

    return (
      <Container>
        <div className={st(classes.root)}>{this.renderEditor()}</div>
        <Block place={BlockAlign.end}>
          <Button priority={PRIORITY.secondary} onClick={cancel}>
            {t('groups-web.cancel')}
          </Button>
          <Tooltip
            content={t('groups-web.about.edit.tip')}
            placement="top"
            disabled={isValid}
          >
            <Button
              onClick={this.onSave}
              priority={PRIORITY.primary}
              disabled={loading || !isValid}
              prefixIcon={loading ? <Loader /> : undefined}
            >
              {t('groups-web.save')}
            </Button>
          </Tooltip>
        </Block>
      </Container>
    );
  }
}

export const AboutEditor = withTranslation()(
  AboutEditorComponent,
) as React.ComponentType<AboutEditorProps>;

AboutEditor.displayName = 'AboutEditor';
