import React from 'react';
import { AppData } from './IAppData';
import { Button } from '../../types/button';

export type AppDataWithStyles = AppData;
export const AppDataContext = React.createContext<AppDataWithStyles>({
  instance: '',
  locale: undefined as any,
  instanceId: undefined as any,
  activeButton: Button.PRIMARY,
});
AppDataContext.displayName = 'AppDataContext';
