import React, { FC } from 'react';
import { useBi, WidgetProps } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import { GroupWidget, GroupWidgetProps } from './GroupWidget';
import { stylesParams } from '../Settings/styles';
import { settingsParams } from '../Settings/settingsParams';
import './project.st.css';
import { GROUP_WRAPPER } from './dataHooks';

const Widget: FC<WidgetProps<GroupWidgetProps>> = (props) => {
  const bi = useBi();
  return (
    <div data-hook={GROUP_WRAPPER}>
      <GroupWidget {...props} bi={bi} />
    </div>
  );
};

export default Widget;
export const GroupWCS = withWidgetWCS(Widget, settingsParams, stylesParams);
GroupWCS.displayName = 'GroupWCS';
export { NEW_POST_BOX } from './Discussion/dataHooks';
