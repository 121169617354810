import React from 'react';

import { Anonymous } from '@wix/social-groups-api/dist/src/model/Member/Member';

import { classes } from './Media.st.css';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import { SiteMembers } from '../../controllers/members/SiteMembers';
import { MembersActions } from '../../controllers/members/MembersActions';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../contexts/GroupActions/WithGroupActions';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { IProGalleryItem } from '../../../../common/ContentEditor/plugins/pro-gallery/ProGalleryItem';
import { Spinner } from '../../../../common/components/Spinner';
import { EmptyState } from '../EmptyState/EmptyState';
import { MediaGallery } from '../Gallery/MediaGallery';
import { compose } from '../../../../common/utils/compose';
import { withSiteMembers } from '../../contexts/SiteMembers/withSiteMembers';
import { groupCreatePostClick } from '@wix/bi-logger-groups/v2';

export interface MediaProps {
  onCreatePostClick(): void;
}

type MediaComponentProps = MediaProps &
  WithGroupProps &
  SiteMembers &
  MembersActions &
  WithGroupActionProps &
  WithTranslation &
  InjectedBiLoggerProps;

interface State {
  loading: boolean;
}

export class MediaComponent extends React.Component<
  MediaComponentProps,
  State
> {
  static displayName = 'MediaComponent';

  readonly state: State = {
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.media!.fetch!();
    this.setState({ loading: false });
  }

  getGalleryItems(): IProGalleryItem[] {
    const { siteMembersMap, media } = this.props;

    try {
      return media.items!.map((mI) => {
        const author = siteMembersMap[mI.metaData.description] || Anonymous;
        mI.metaData.description = JSON.stringify(author);
        return mI;
      });
    } catch (e) {
      console.log('MediaComponent getGalleryItems Error', e);
      return [];
    }
  }

  handleCreatePostClick = () => {
    const { group, bi, onCreatePostClick } = this.props;
    bi.report(
      groupCreatePostClick({
        origin: 'media_tab_btn',
        groupId: group.groupId,
      } as any),
    );
    onCreatePostClick();
  };

  render() {
    const { t, media } = this.props;
    const { loading } = this.state;

    if (loading || !media || !media.items) {
      return <Spinner label={t('groups-web.loading')} />;
    }

    const items = this.getGalleryItems();

    if (!items.length) {
      return (
        <EmptyState
          className={classes.emptyRoot}
          title={t('groups-web.media.empty.title')}
          content={t('groups-web.media.empty.description')}
          button={{
            label: t('groups-web.discussion.create-new'),
            onClick: this.handleCreatePostClick,
          }}
        />
      );
    }

    return (
      <React.Suspense fallback={<Spinner label={t('groups-web.loading')} />}>
        <MediaGallery items={items} total={media.total} />
      </React.Suspense>
    );
  }
}

const enhanced = compose(
  WithGroupActions,
  WithGroup,
  withSiteMembers,
  withBi,
  withTranslation(),
);

export const Media = enhanced(
  MediaComponent,
) as React.ComponentType<MediaProps>;

export default Media;
