import React from 'react';
import moment from 'moment';

import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text } from 'wix-ui-tpa/Text';

import { st, classes } from './AuthorInfo.st.css';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ActivityStatus } from '../ActivityPost';
import { TextButton } from 'wix-ui-tpa/TextButton';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { getAllowedRoles } from '../../../../../common/components/RoleBadges/helpers';
import { RoleBadges } from '../../../../../common/components/RoleBadges/RoleBadges';
import { IFeedItemActivity } from '../../../types/IFeedItem';
import { MemberBadges } from '../MemberBadges/MemberBadges';

interface AuthorInfoProps {
  avatarUrl: string;
  activity?: IFeedItemActivity;
  name: string;
  timeStamp: Date;
  relationship: ApiTypes.v1.RelationshipWithGroup;
  roles: ApiTypes.v1.RoleInGroup[];
  onClick(): void;
  badges: string[];
}

export const AuthorInfoComponent: React.FC<
  AuthorInfoProps & WithTpaComponentsConfigProps
> = ({
  avatarUrl,
  name,
  timeStamp,
  mobile,
  relationship,
  roles,
  activity,
  onClick,
  badges,
}) => {
  const allowedRoles = getAllowedRoles(roles);
  return (
    <div className={st(classes.root, { mobile } as any)}>
      <div onClick={onClick}>
        <Avatar
          size={mobile ? AvatarSize.medium : AvatarSize.large}
          src={avatarUrl}
          className={classes.avatar}
        />
      </div>
      <div className={classes.avatarDivider} />
      <div>
        <TextButton onClick={onClick} className={classes.name}>
          {name}
        </TextButton>
        {activity && (
          <>
            <div className={classes.titleDivider} />
            <ActivityStatus activity={activity} />
          </>
        )}
        <div className={classes.description}>
          {allowedRoles.length ? (
            <>
              <RoleBadges relationship={relationship} roles={allowedRoles} />
              <div className={classes.subtitleDivider} />
            </>
          ) : null}
          <Text className={classes.timestamp}>
            {moment(timeStamp).fromNow()}
            <MemberBadges badges={badges} />
          </Text>
        </div>
      </div>
    </div>
  );
};

export const AuthorInfo = withTpaComponentsConfig(AuthorInfoComponent);
