import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './Details.st.css';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { DETAILS, DETAILS_TITLE } from './dataHooks';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { RoleBadges } from '../../../../common/components/RoleBadges/RoleBadges';
import { compose } from '../../../../common/utils/compose';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import { MembersCount } from '../MembersCount/MembersCount';
import { settingsParams } from '../../Settings/settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

// TODO: extract shared translations
export const DetailsComponent: React.FC<WithGroupProps & TPAComponentsConfig> =
  ({ mobile, group }) => {
    const {
      details,
      relationship,
      roles,
      // @ts-expect-error
      settings: { privacyLevel },
    } = group;
    const { t } = useTranslation();
    const settings = useSettings();
    const showMembersCountKey = getSettingsKeyFor('showMembersCount', mobile!);
    const showMembersCount = settings.get(settingsParams[showMembersCountKey]);

    return (
      <div data-hook={DETAILS} className={st(classes.root, { mobile } as any)}>
        <div className={classes.titleAndRole}>
          <Text
            tagName="h1"
            data-hook={DETAILS_TITLE}
            className={classes.title}
          >
            {details!.title}
          </Text>
          {!mobile && (
            <>
              <div className={classes.titleAndRoleDelimiter} />
              <RoleBadges relationship={relationship!} roles={roles!} />
            </>
          )}
        </div>
        <div className={classes.privacyAndMembers}>
          {mobile && roles!.length > 0 && (
            <>
              <RoleBadges relationship={relationship!} roles={roles!} />
              <div className={classes.titleAndRoleDelimiter} />
            </>
          )}
          <Text className={classes.groupInfoItem}>
            {t(`groups-web.types.${privacyLevel.toLowerCase()}.name`)}
          </Text>
          {showMembersCount && (
            <>
              <Text className={classes.groupInfoItem}>&nbsp;·&nbsp;</Text>
              <MembersCount className={classes.groupInfoItem} />
            </>
          )}
        </div>
      </div>
    );
  };
const enhance = compose(WithGroup, withTPAConfig);

export const Details = enhance(DetailsComponent);
