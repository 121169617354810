import React from 'react';
import { classes } from '../Members.st.css';

import { AdminActions } from './AdminActions';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { ThreeDots, ThreeDotsActions } from '../../ThreeDots/ThreeDots';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { compose } from '../../../../../common/utils/compose';
import { memberTabAdminActionClick } from '@wix/bi-logger-groups/v2';

export interface MemberActionsProps extends AdminActions {
  isGroupAdmin: boolean;
  isSiteAdmin: boolean;
  hasAnswers: boolean;
  memberId: string;
  groupId: string;
}

type Props = MemberActionsProps & WithTranslation & InjectedBiLoggerProps;

interface State {
  isOpen: boolean;
}

class MemberActionsComponent extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;
    return (
      <ThreeDots
        onClose={this.close}
        isOpen={isOpen}
        onOpen={this.open}
        items={this.getMemberMenuItems()}
        iconClassName={classes.threeDotsIcon}
        className={classes.threeDotsMenu}
      />
    );
  }

  private readonly close = () => {
    this.setState({ isOpen: false });
  };

  private readonly open = (e: any) => {
    e.stopPropagation();
    this.setState({
      isOpen: true,
    });
  };

  private getMemberMenuItems(): ThreeDotsActions[] {
    const items = [
      this.getViewAnswers(),
      this.getChangeAdmin(),
      this.getRemoveMember(),
    ];

    return items.filter((item) => !!item) as any;
  }

  private getViewAnswers() {
    const { t, hasAnswers } = this.props;

    return (
      hasAnswers && {
        content: t('groups-web.members.more-actions.view-answers.menu-item'),
        onClick: this.viewAnswers,
      }
    );
  }

  private readonly viewAnswers = (e?: any) => {
    const { bi, groupId, memberId, onViewAnswers } = this.props;
    e && e.stopPropagation();

    bi.report(
      memberTabAdminActionClick({
        group_id: groupId,
        action: 'view_answers',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
      }),
    );

    this.close();
    onViewAnswers(memberId);
  };

  private getRemoveMember() {
    const { t } = this.props;
    return {
      content: t(
        'groups-web.members.more-actions.remove-member-from-group.menu-item',
      ),
      onClick: this.removeMember,
    };
  }

  private readonly removeMember = (e: any) => {
    const { bi, groupId, memberId, onRemove } = this.props;
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    bi.report(
      memberTabAdminActionClick({
        group_id: groupId,
        action: 'remove_member',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      }),
    );

    this.close();
    onRemove(memberId);
  };

  private getAddAdmin() {
    const { t } = this.props;
    return {
      content: t('groups-web.members.more-actions.add-admin-role.menu-item'),
      onClick: this.addAdmin,
    };
  }

  private readonly addAdmin = (e: any) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    const { bi, groupId, memberId, onChangeAdmin } = this.props;
    bi.report(
      memberTabAdminActionClick({
        group_id: groupId,
        action: 'set_admin',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      }),
    );
    this.close();
    onChangeAdmin(memberId);
  };

  private getRemoveAdmin() {
    const { t } = this.props;
    return {
      content: t('groups-web.members.more-actions.remove-admin-role.menu-item'),
      onClick: this.removeAdmin,
    };
  }

  private readonly removeAdmin = (e: any) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    const { bi, groupId, memberId, onChangeAdmin } = this.props;
    bi.report!(
      memberTabAdminActionClick({
        group_id: groupId,
        action: 'remove_admin',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      }),
    );

    this.close();
    onChangeAdmin(memberId);
  };

  private getChangeAdmin() {
    const { isGroupAdmin, isSiteAdmin } = this.props;
    if (isGroupAdmin && !isSiteAdmin) {
      return this.getRemoveAdmin();
    }
    if (!isGroupAdmin) {
      return this.getAddAdmin();
    }
    return;
  }
}
const enhance = compose(withTranslation(), withBi);
export const MemberActions = enhance(
  MemberActionsComponent,
) as React.ComponentType<MemberActionsProps>;
