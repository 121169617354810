import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupActionsConsumer } from './GroupActionsContext';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';

export interface GroupUpdate {
  updateGroup(
    paths: string[],
    details?: ApiTypes.v1.GroupDetails,
    settings?: ApiTypes.v1.GroupSettings,
  ): void;
  setEditMode(mode: UpdateProgress): void;
}

export interface UpdateContext {
  updateProgress: UpdateProgress;
}

export const withGroupUpdate =
  <P extends GroupUpdate>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof GroupUpdate>> =>
  (props) =>
    (
      <GroupActionsConsumer>
        {({ updateGroup, setEditMode }) => (
          <WrappedComponent
            {...(props as P)}
            updateGroup={updateGroup}
            setEditMode={setEditMode}
          />
        )}
      </GroupActionsConsumer>
    );
