import React, { FC, useContext, useEffect, useState } from 'react';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import * as eventHelpers from '@wix/social-groups-api/dist/src/model/Events/helpers';

import { Box } from '../../../../../common/components/Box/Box';
import { EventsContext } from '../../../contexts/events/EventsContext';
import { GroupActionsContext } from '../../../contexts/GroupActions/GroupActionsContext';
import { Tab } from '../../../../../common/controllers';

import * as discussionStyles from '../../Discussion/Discussion.st.css';
import { classes } from './EventsWidget.st.css';

export const EventsWidget: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { events, fetch } = useContext(EventsContext);
  const { experiments } = useExperiments();
  const { changeTab } = useContext(GroupActionsContext);

  useEffect(() => {
    if (!experiments.enabled('specs.groups.EventsIntegration')) {
      return;
    }

    void fetchEvents();

    async function fetchEvents() {
      setLoading(true);
      await fetch();
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  }

  if (!events?.length) {
    return null;
  }

  return (
    <Box article>
      <Text className={discussionStyles.classes.widgetHeader}>
        {t('groups-web.discussion.events.widget.title')}
      </Text>
      <ul>
        {events.slice(0, 2).map((event) => {
          const [day, weekday, month] = eventHelpers
            .getStartDate(event, 'DD/ddd/MMM')
            .split('/');

          return (
            <li key={event.id}>
              <Text
                tagName="p"
                className={classes.eventTitle}
                typography={TYPOGRAPHY.listText}
              >
                {`${day} ${month}, ${weekday} | '${event.title}'`}
              </Text>
            </li>
          );
        })}
      </ul>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={navigateToEventsTab}
      >
        {t('groups-web.discussion.events.widget.view-all')}
      </TextButton>
    </Box>
  );

  function navigateToEventsTab() {
    changeTab(Tab.EVENTS);
  }
};
