import React from 'react';
import moment from 'moment';
import { PendingMember } from '@wix/social-groups-api/dist/src/model/Member/PendingMember';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { AvatarSize } from 'wix-ui-tpa/Avatar';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { st, classes } from './PendingMembers.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { ExpandableContainer } from '../../../../common/components/Container/ExpandableContainer';
import { UserCard } from '../../../../common/components/Cards/UserCard';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { StyledDivider } from '../../../../common/components/Divider/Divider';
import { Block } from '../../../../common/components/Block/Block';
import { Button } from '../../../../common/components/Button';
import { BlockAlign } from '../../../../common/components/Block';

interface PendingMembersComponentProps {
  pendingMembers: any; // TODO: fix

  approveMember(siteMemberId: ApiTypes.v1.GroupMemberResponse): void;

  rejectMember(siteMemberId: ApiTypes.v1.GroupMemberResponse): void;
  onUserClick?(member: ApiTypes.v1.PendingMemberResponse): void;
  loadMore?(): void;
  onShowAnswersClick?(member: ApiTypes.v1.PendingMemberResponse): void;
  total: number;
  loading?: boolean;
  mobile?: boolean;
  className?: string;
}

interface PendingMembersComponentState {}

export const PENDING_MEMBERS_EXPAND = 'pending-members-expand';
export class PendingMembersComponent extends React.Component<
  PendingMembersComponentProps & WithTranslation,
  PendingMembersComponentState
> {
  state: PendingMembersComponentState = {};
  render() {
    const {
      pendingMembers,
      t,
      onUserClick,
      loading,
      onShowAnswersClick,
      mobile,
      ...rest
    } = this.props;
    return (
      <ExpandableContainer
        initialExpanded={!loading}
        loading={loading}
        title={this.renderTitle()}
        className={st(classes.root, { mobile } as any, rest.className)}
        data-hook={PENDING_MEMBERS_EXPAND}
      >
        <div className={classes.membersList}>
          {pendingMembers.map((m: any, i: any) => {
            const member = new PendingMember(m);
            const date = `${moment(member.getRequestDate()).fromNow()}`;
            const divider = i < pendingMembers.length - 1;
            return (
              <>
                <UserCard
                  key={member.getId()}
                  name={member.getName(
                    !loading ? t('groups-web.member.anonymous') : undefined,
                  )}
                  pic={member.getImageUrl()}
                  subtitle={date}
                  userAction={!mobile ? this.getPendingActions(m) : null}
                  avatarSize={AvatarSize.large}
                  onUserClick={() => (onUserClick ? onUserClick(m) : {})}
                  loading={loading}
                  className={classes.userCard}
                  mobile={mobile}
                >
                  {member.hasAnswers() ? (
                    <TextButton
                      priority={TEXT_BUTTON_PRIORITY.primary}
                      className={classes.showAnswers}
                      onClick={() => onShowAnswersClick!(m)}
                    >
                      {t('groups-web.membership-questions.show-answers', {
                        count: member.getAnswersCount(),
                      })}
                    </TextButton>
                  ) : null}
                </UserCard>

                {mobile ? (
                  <div className={classes.actions}>
                    {this.getPendingActions(m)}
                  </div>
                ) : null}
                {divider && <StyledDivider className={classes.divider} />}
              </>
            );
          })}
          {this.showMore()}
        </div>
      </ExpandableContainer>
    );
  }

  private getPendingActions(member: ApiTypes.v1.GroupMemberResponse) {
    const { approveMember, rejectMember, t } = this.props;
    return (
      <Block autoContent={true}>
        <Button
          onClick={() => rejectMember(member)}
          priority={PRIORITY.secondary}
          size={SIZE.tiny}
          className={classes.actionButton}
          fullWidth
        >
          {t('groups-web.members.pending.decline')}
        </Button>
        <Button
          onClick={() => approveMember(member)}
          priority={PRIORITY.primary}
          size={SIZE.tiny}
          className={classes.actionButton}
          fullWidth
        >
          {t('groups-web.members.pending.approve')}
        </Button>
      </Block>
    );
  }

  private showMore() {
    const { loadMore, total, t, pendingMembers, loading, ...rest } = this.props;
    if (pendingMembers.length === 0 && loading) {
      return;
    }
    const show = this.hasMore();
    return show ? (
      <>
        <StyledDivider className={classes.divider} />
        <Block place={BlockAlign.center} autoContent>
          <TextButton
            onClick={loadMore}
            priority={TEXT_BUTTON_PRIORITY.primary}
          >
            {t('groups-web.showMore')}
          </TextButton>
        </Block>
      </>
    ) : null;
  }

  private hasMore() {
    const { loadMore, total, pendingMembers } = this.props;
    return total > pendingMembers.length && loadMore;
  }

  private renderTitle() {
    const { total, t } = this.props;
    const title = `${t('groups-web.members.pending.requests')}`;
    const number = total < 10 ? total : '9+';
    return (
      <div className={classes.titleWrapper}>
        <Text typography={TYPOGRAPHY.runningText} className={classes.title}>
          {title}
        </Text>
        <span className={classes.badge}>{number}</span>
      </div>
    );
  }
}

export const PendingMembers = withTranslation()(PendingMembersComponent);
PendingMembers.displayName = 'PendingMembers';
