import React from 'react';

import { memberWrapper } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { AvatarSize } from 'wix-ui-tpa/Avatar';

import { classes } from './Media.st.css';
import { UserCard } from '../../../../common/components/Cards/UserCard';

export interface ThumbnailProps {
  description: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = (props) => {
  let description;
  try {
    description = JSON.parse(
      props.description,
    ) as ApiTypes.v1.GroupMemberResponse;
  } catch (e) {}
  const { name, imageUrl } = memberWrapper(description as any);
  return (
    <div className={classes.description}>
      <UserCard
        name={name!.nick}
        pic={imageUrl}
        avatarSize={AvatarSize.small}
      />
    </div>
  );
};
