import React from 'react';
import BreadcrumbsChevronRight from 'wix-ui-icons-common/system/BreadcrumbsChevronRight';
import { st, classes } from './Breadcrumbs.st.css';
import scss from './breadcrumbs.scss';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';
import classnames from 'classnames';

export interface BreadcrumbsProps {
  items: {
    label: string;
    href?: string;
    action?(): void;
  }[];
  className?: string;
}
const PREFIX = 'breadcrumbs';
export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  ...restProps
}) => {
  const { rtl } = React.useContext(TPAComponentsContext);

  return (
    <nav
      aria-label="breadcrumbs"
      role="navigation"
      className={st(
        classes.root,
        {},
        classnames(scss.breadcrumbs, restProps.className),
      )}
    >
      <ol className={scss.list}>
        {items.map(({ action, label, href }, index) => (
          <li key={index} className={scss.item}>
            {href || action ? (
              <TextButton
                as={href ? 'a' : 'button'}
                className={classes.text}
                href={href}
                onClick={action}
              >
                {label}
              </TextButton>
            ) : (
              <Text
                className={classes.text}
                aria-current="true"
                typography={TYPOGRAPHY.runningText}
              >
                {label}
              </Text>
            )}
            {index < items.length - 1 ? (
              <BreadcrumbsChevronRight
                aria-hidden="true"
                className={classnames(scss.icon, classes.icon, {
                  [scss.iconRotated]: rtl,
                })}
              />
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
Breadcrumbs.displayName = 'Breadcrumbs';
