import React from 'react';
import { MemberInvites } from './MemberInvites';

const context = React.createContext<MemberInvites>({
  memberInvitesLink: '',
  loadMemberInvitesLink() {},
});

context.displayName = 'MemberInvitesContext';

export const MemberInvitesProvider = context.Provider;
export const MemberInvitesConsumer = context.Consumer;
