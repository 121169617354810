import React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';

import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../contexts/SiteMembers/withSiteMembers';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import { Button } from '../../../../common/components/Button';
import { compose } from '../../../../common/utils/compose';

interface FollowButtonComponentProps {
  siteMemberId: string;
}

type FollowButtonProps = FollowButtonComponentProps &
  WithSiteMembers &
  WithGroupProps;

const FollowButtonComponent: React.FC<FollowButtonProps> = (props) => {
  const {
    followingMembers,
    currentMember,
    openUserProfile,
    unfollowMember,
    followMember,
    siteMemberId,
  } = props;
  const { t } = useTranslation();

  const isFollowing = followingMembers.includes(siteMemberId);
  const isCurrentUser = currentMember.siteMemberId === siteMemberId;

  return (
    <Button
      priority={
        isCurrentUser || isFollowing ? PRIORITY.secondary : PRIORITY.primary
      }
      onClick={(e) => {
        e.stopPropagation();

        if (isCurrentUser) {
          openUserProfile(siteMemberId);
          return;
        }

        if (isFollowing) {
          unfollowMember(siteMemberId);
          return;
        }

        followMember(siteMemberId);
      }}
    >
      {isCurrentUser
        ? t('groups-web.my-profile')
        : isFollowing
        ? t('groups-web.following')
        : t('groups-web.follow')}
    </Button>
  );
};

const enhance = compose(WithGroup, withSiteMembers);

export const FollowButton = enhance(
  FollowButtonComponent,
) as React.ComponentType<FollowButtonComponentProps>;
