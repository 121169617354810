import React, { CSSProperties } from 'react';
import { classes, st, vars } from './Block.st.css';
import staticStyles from './block.scss';
import { BlockAlign, BlockFlow, BlockPlace } from './BlockAlign';
import { ClassNamesBuilder } from '../ClassNamesBuilder/ClassNamesBuilder';

interface StylesOverride {
  gridTemplateColumns: string;
}

/**
 * Block Props
 */
export interface BlockProps {
  /**
   * align items inside the block (css `align-items`)
   */
  align?: BlockAlign;
  justify?: BlockAlign;
  /**
   * places items inside (css `place-content`)
   */
  place?: BlockAlign | BlockPlace;
  /**
   *  node to render in the end of the block (css `justify-self: end`)
   */
  end?: React.ReactNode;
  /**
   * translates to `grid-auto-flow` CSS property
   * @default 'col'
   */
  flow?: BlockFlow;
  autoContent?: boolean;
  /**
   * `grid-template-columns` CSS property
   * https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  stylesOverride?: StylesOverride;
}

function stylesToVars(stylesOverride: StylesOverride, cssProps: object) {
  if (!stylesOverride) {
    return;
  }
  const style = Object.keys(stylesOverride).reduce((acc, k) => {
    // @ts-expect-error
    acc[cssProps[k]] = stylesOverride[k];
    return acc;
  }, {});
  return style as CSSProperties;
}

/**
 * Block
 *
 * Component for basic layout with `display: grid`. All `HTMLElement` props are translated to the top component container.
 */
export const Block: React.FunctionComponent<
  BlockProps & React.HTMLAttributes<HTMLElement>
> = (props) => {
  const {
    align,
    place,
    end,
    children,
    flow,
    autoContent,
    justify,
    className,
    stylesOverride,
    ...rest
  } = props;
  const styleProps = {
    align,
    place,
    flow,
    autoContent,
    justify,
    root: true,
  };
  const _classes = ClassNamesBuilder.fromProps(styleProps)
    .withStyles(classes)
    .withStyles(staticStyles)
    .addClassName(className)
    .build();
  const style = stylesToVars(stylesOverride!, vars);
  return (
    <div className={st(classes.root, {}, _classes)} {...rest} style={style}>
      {children}
      {end && <div className={staticStyles.end}>{end}</div>}
    </div>
  );
};
Block.defaultProps = {
  flow: BlockFlow.col,
};
Block.displayName = 'Block';
