import { useMemo } from 'react';

import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { RicosTheme } from '@wix/ricos-common/dist/types';

import { getWixColor } from '@wix/yoshi-flow-editor/tpa-settings';
import { OOIHost } from '../../../../common/components/OOIComponent/OOIHost';

export function useRicosTheme(host: IHostProps, forceBlackAndWhite?: boolean) {
  const { id, style } = host;
  const { siteColors, styleParams } = style;
  const { postsTextColor, postsBackgroundColor } = styleParams.colors;
  const { postsTextFont } = styleParams.fonts;

  const blackHex = '#000000';
  const whiteHex = '#ffffff';

  const theme = useMemo<RicosTheme>(() => {
    return {
      parentClass: id,
      paletteConfig: {
        contentBgColor: false,
      },
      typography: {
        fontFamily: postsTextFont?.family,
      },
      palette: {
        textColor: getTextColor(),
        bgColor: getBackgroundColor(),
        actionColor: getColor('color-8'),
        disabledColor: getColor('color-3'),
        fallbackColor: getColor5(),
        textColorLow: getColor5(),
      },
      customStyles: getCustomStyles(),
    };
  }, [postsTextColor, postsBackgroundColor, postsTextFont, id]);
  return { theme };

  function getCustomStyles() {
    const { postsTextFont } = styleParams.fonts;
    const ooiHost = new OOIHost(host);
    const postsTextFontSize = postsTextFont?.size;
    const { mobilePostsTextFontSize } = styleParams.numbers;

    const fontSize = `${
      (ooiHost.isMobile() ? mobilePostsTextFontSize : postsTextFontSize) || 16
    }px`;

    return {
      p: {
        fontSize,
      },
      link: {
        fontSize,
      },
    };
  }

  function getTextColor(): string {
    if (forceBlackAndWhite) {
      return blackHex;
    }
    return postsTextColor?.value || getColor('color-5');
  }

  function getBackgroundColor(): string {
    if (forceBlackAndWhite) {
      return whiteHex;
    }
    return postsBackgroundColor?.value || getColor('color-1');
  }

  function getColor5(): string {
    if (forceBlackAndWhite) {
      return blackHex;
    }
    return getColor('color-5');
  }

  function getColor(reference: any) {
    return getWixColor({ colors: siteColors, reference })?.value;
  }
}
