import React from 'react';
import { IProfileContext, ProfileContext } from './Profile';

export interface WithProfileProps extends IProfileContext {}

export const withProfile =
  <P extends WithProfileProps>(WrappedComponent: React.ComponentType<P>) =>
  (props: any) => {
    return (
      <ProfileContext.Consumer>
        {(context: IProfileContext) => (
          <WrappedComponent {...props} {...context} />
        )}
      </ProfileContext.Consumer>
    );
  };
