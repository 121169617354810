import React from 'react';

import { HostContext } from './HostContext';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';

export const withHostProps =
  <P extends IHostProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof IHostProps>> =>
  (props) =>
    (
      <HostContext.Consumer>
        {(host) => host && <WrappedComponent {...(props as P)} {...host} />}
      </HostContext.Consumer>
    );
