import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { Origins } from '../../../../common/bi-logger/customTab/origins';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { EmptyState } from '../EmptyState/EmptyState';
import { RichContentViewer } from '../RichContent/lazy';

import { classes } from './CustomTab.st.css';
import { PRESETS } from '../RichContent/types';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useMount } from '../../../../common/hooks/useMount';

interface ViewerProps {
  handleEditClick?(biOrigin?: string): void;

  contentState: any;
  canEdit: boolean;
}

export const Viewer = (props: ViewerProps) => {
  const { handleEditClick, contentState, canEdit } = props;
  const { t } = useTranslation();
  const mount = useMount();
  const onEmptyClick = React.useCallback(() => {
    handleEditClick && handleEditClick(Origins.EMPTY_STATE);
  }, [handleEditClick]);

  const onEditClick = React.useCallback(() => {
    handleEditClick && handleEditClick(Origins.SITE_EDIT); // TODO: no BI event for edit click?
  }, [handleEditClick]);

  if (!contentState) {
    return (
      <EmptyState
        title={t('groups-web.custom-tab.empty.title')}
        content={t('groups-web.custom-tab.empty.content')}
        boxProps={{
          withSideBorders: false,
          withTopBottomBorders: false,
        }}
        button={
          canEdit
            ? {
                label: t('groups-web.custom-tab.empty.button'),
                onClick: onEmptyClick,
              }
            : undefined
        }
      />
    );
  }

  function renderViewer() {
    return mount ? (
      <React.Suspense fallback={<Spinner />}>
        <RichContentViewer
          disablePreview
          className={classes.ricos}
          content={contentState}
          preset={PRESETS.CUSTOM_TAB}
          usage="CustomTab"
        />
        {canEdit && (
          <div className={classes.actions}>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={onEditClick}
            >
              {t('groups-web.custom-tab.edit')}
            </TextButton>
          </div>
        )}
      </React.Suspense>
    ) : (
      <Spinner offset="L" />
    );
  }

  return <div className={classes.root}>{renderViewer()}</div>;
};

Viewer.displayName = 'CustomTabViewer';
