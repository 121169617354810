import React from 'react';
import { MembershipChangeAction } from '../../types/MembershipAction';
import { GroupActionsConsumer } from '../GroupActions/GroupActionsContext';

export const withMembershipChangeAction =
  <P extends MembershipChangeAction>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<P> =>
  (props) =>
    (
      <GroupActionsConsumer>
        {({ changeMembership, openJoinDialog }) => (
          <WrappedComponent
            {...props}
            changeMembership={changeMembership}
            openJoinDialog={openJoinDialog}
          />
        )}
      </GroupActionsConsumer>
    );
