import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { st, classes } from './MembersCount.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import { getMembersLabelWithCount } from '../MembersLabel/MembersLabelWithCount';
import { compose } from '../../../../common/utils/compose';

export const MEMBERS_COUNT = 'members-count';

const MembersCountComponent: React.FC<WithGroupProps & WithTranslation> = ({
  t,
  group,
  ...rest
}) => {
  const groupWrapper = new GroupWrapper(group);
  const membersCount = groupWrapper.getMembersCount();
  const membersLabel = groupWrapper.getMembersName();
  const label = getMembersLabelWithCount(membersLabel, membersCount, t);
  return (
    <Text
      data-hook={MEMBERS_COUNT}
      className={st(classes.root, {}, rest.className)}
    >
      {label}
    </Text>
  );
};

const enhance = compose(withTranslation(), WithGroup);
export const MembersCount = enhance(MembersCountComponent);
