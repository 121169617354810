import React from 'react';
import { Container } from './Container';
import { Block, BlockAlign } from '../Block';
import { IconButton } from 'wix-ui-tpa/IconButton';
import { ArrowDown } from '../Icons/ArrowDown';
import { Spinner } from '../Spinner';

import { st, classes } from './ExpandableContainer.st.css';
import { Divider } from 'wix-ui-tpa/Divider';

interface ExpandableContainerProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'title'> {
  title: React.ReactNode;
  showMore?: React.ReactNode;
  initialExpanded?: boolean;
  loading?: boolean;
  className?: string;
}

interface ExpandableContainerState {
  expanded: boolean;
}

export class ExpandableContainer extends React.Component<
  ExpandableContainerProps,
  ExpandableContainerState
> {
  static defaultProps = {
    initialExpanded: true,
  };
  static displayName = 'ExpandableContainer';
  state: ExpandableContainerState = { expanded: this.props.initialExpanded! };

  render() {
    const { children, title, showMore, loading, ...rest } = this.props;
    const { expanded } = this.state;
    return (
      <Container className={st(classes.root, { expanded }, rest.className)}>
        <Block
          end={this.renderExpand()}
          className={classes.expand}
          onClick={this.expand}
          data-hook={(rest as any)['data-hook']}
        >
          {title}
        </Block>
        {expanded && !loading && (
          <>
            <Divider className={classes.divider} />
            <Container>
              {children}
              {showMore && (
                <Block
                  place={BlockAlign.center}
                  autoContent
                  className={classes.showMore}
                >
                  {showMore}
                </Block>
              )}
            </Container>
          </>
        )}
      </Container>
    );
  }

  private renderExpand() {
    const { loading } = this.props;
    const { expanded } = this.state;
    if (loading && expanded) {
      return <Spinner offset="NONE" />;
    }
    return <IconButton icon={<ArrowDown />} />;
  }

  private readonly expand = () => {
    this.setState({ expanded: !this.state.expanded });
  };
}
