import React from 'react';

interface MemberBadgesProps {
  badges: string[];
}

export const MemberBadges: React.FC<MemberBadgesProps> = ({ badges }) => {
  if (!badges.length) {
    return null;
  }
  const separator = ' • ';
  return <span>{`${separator}${badges.join(separator)}`}</span>;
};
