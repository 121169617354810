import { ISettingsParam } from '@wix/yoshi-flow-editor/tpa-settings';

import { ButtonType } from '../../../common/settings/groups-list/settingsConstants';

import {
  CoverImageLayout,
  MediaImagesPerRow,
  MediaLayout,
} from './settingsConstants';

export type ISettingsParams = {
  /**
   * Design settings
   */
  buttonType: ISettingsParam<ButtonType>;

  /**
   * General layout settings
   */
  coverImageLayout: ISettingsParam<CoverImageLayout>;
  coverImageFullWidth: ISettingsParam<boolean>;
  coverImageHeight: ISettingsParam<number>;
  layoutSpacing: ISettingsParam<number>;
  mobileCoverImageLayout: ISettingsParam<CoverImageLayout>;
  mobileCoverImageFullWidth: ISettingsParam<boolean>;
  mobileCoverImageHeight: ISettingsParam<number>;
  mobileLayoutSpacing: ISettingsParam<number>;

  /**
   * Media layout settings
   */
  mediaLayout: ISettingsParam<MediaLayout>;
  mobileMediaLayout: ISettingsParam<MediaLayout>;
  imagesPerRow: ISettingsParam<MediaImagesPerRow>;

  /**
   * Discussions display settings
   */
  showReactions: ISettingsParam<boolean>;
  showMembersCount: ISettingsParam<boolean>;
  showShareButton: ISettingsParam<boolean>;
  showBreadcrumbs: ISettingsParam<boolean>;
  mobileShowReactions: ISettingsParam<boolean>;
  mobileShowMembersCount: ISettingsParam<boolean>;
  mobileShowShareButton: ISettingsParam<boolean>;
  mobileShowBreadcrumbs: ISettingsParam<boolean>;

  /**
   * Media display settings
   */
  showNameOnHover: ISettingsParam<boolean>;
  mobileShowNameOnHover: ISettingsParam<boolean>;

  /**
   * Members display settings
   */
  showFilters: ISettingsParam<boolean>;
  showContent: ISettingsParam<boolean>;
  showFollowButton: ISettingsParam<boolean>;

  /**
   * About display settings
   */
  showActivity: ISettingsParam<boolean>;
  mobileShowActivity: ISettingsParam<boolean>;

  /**
   * Text settings
   */
  newPostBoxLabel: ISettingsParam<string>;
};

export const settingsParams: ISettingsParams = {
  /**
   * Design settings
   */
  buttonType: {
    key: 'buttonType',
    getDefaultValue: () => ButtonType.rectangle,
  },

  /**
   * General layout settings
   */
  coverImageFullWidth: {
    getDefaultValue: () => true,
    key: 'coverImageFullWidth',
  },
  coverImageHeight: {
    getDefaultValue: () => 240,
    key: 'coverImageHeight',
  },
  layoutSpacing: {
    getDefaultValue: () => 20,
    key: 'layoutSpacing',
  },
  coverImageLayout: {
    getDefaultValue: () => CoverImageLayout.large,
    key: 'coverImageLayout',
  },
  mobileCoverImageFullWidth: {
    getDefaultValue: () => true,
    key: 'mobileCoverImageFullWidth',
  },
  mobileCoverImageHeight: {
    getDefaultValue: () => 240,
    key: 'mobileCoverImageHeight',
  },
  mobileLayoutSpacing: {
    getDefaultValue: () => 20,
    key: 'mobileLayoutSpacing',
  },
  mobileCoverImageLayout: {
    getDefaultValue: () => CoverImageLayout.large,
    key: 'mobileCoverImageLayout',
  },

  /**
   * Media layout settings
   */
  imagesPerRow: {
    getDefaultValue: () => 5,
    key: 'imagesPerRow',
  },
  mediaLayout: {
    getDefaultValue: () => MediaLayout.grid,
    key: 'mediaLayout',
  },
  mobileMediaLayout: {
    getDefaultValue: () => MediaLayout.grid,
    key: 'mobileMediaLayout',
  },
  /**
   * Discussions display settings
   */
  showBreadcrumbs: {
    key: 'showBreadcrumbs',
    getDefaultValue: () => false,
  },
  showMembersCount: {
    key: 'showMembersCount',
    getDefaultValue: () => true,
  },
  showReactions: {
    key: 'showReactions',
    getDefaultValue: () => true,
  },
  showShareButton: {
    key: 'showShareButton',
    getDefaultValue: () => false,
  },
  mobileShowBreadcrumbs: {
    key: 'mobileShowBreadcrumbs',
    getDefaultValue: () => false,
  },
  mobileShowMembersCount: {
    key: 'mobileShowMembersCount',
    getDefaultValue: () => true,
  },
  mobileShowReactions: {
    key: 'mobileShowReactions',
    getDefaultValue: () => true,
  },
  mobileShowShareButton: {
    key: 'mobileShowShareButton',
    getDefaultValue: () => false,
  },

  /**
   * Media display settings
   */
  showNameOnHover: {
    key: 'showNameOnHover',
    getDefaultValue: () => true,
  },
  mobileShowNameOnHover: {
    key: 'mobileShowNameOnHover',
    getDefaultValue: () => true,
  },

  /**
   * Members display settings
   */
  showContent: {
    key: 'showContent',
    getDefaultValue: () => false,
  },
  showFilters: {
    key: 'showFilters',
    getDefaultValue: () => false,
  },
  showFollowButton: {
    key: 'showFollowButton',
    getDefaultValue: () => false,
  },

  /**
   * About display settings
   */
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
  },
  mobileShowActivity: {
    getDefaultValue: () => true,
    key: 'mobileShowActivity',
  },

  /**
   * Text settings
   */
  newPostBoxLabel: {
    getDefaultValue: () => '',
    key: 'newPostBoxLabel',
  },
};
