import * as React from 'react';
import { Member } from './Member/Member';
import { isAdmin } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupContext } from '../../contexts/Group/GroupContext';
import { SiteMembersContext } from '../../contexts/SiteMembers/SiteMembers';
import { Theme } from '../../../../common/context/theme/Theme';

export interface MembersListProps extends Theme {
  members: ApiTypes.v1.GroupMemberResponse[];
  withMoreActions?: boolean; // TODO: refactor for composition https://reactjs.org/docs/composition-vs-inheritance.html
  className?: string;
  onChangeAdmin?(memberId: string): void;
  onRemove?(memberId: string): void;
  onViewAnswers?(memberId: string): void;
}

export const MembersList = (props: MembersListProps) => {
  const {
    className,
    withMoreActions,
    members,
    onChangeAdmin,
    onRemove,
    onViewAnswers,
    forceBlackAndWhite,
  } = props;
  const { group, currentMember } = React.useContext(GroupContext);
  const { mobile } = React.useContext(TPAComponentsContext);
  const {
    membersActions: { openUserProfile },
  } = React.useContext(SiteMembersContext);
  const renderMember = (member: any) => {
    const { siteMemberId } = member;
    const isCurrentUser = currentMember.siteMemberId === siteMemberId;
    return (
      <Member
        forceBlackAndWhite={forceBlackAndWhite}
        groupId={group.groupId!}
        isCurrentUser={isCurrentUser}
        isAdmin={isAdmin(group)}
        member={member}
        mobile={mobile!}
        withMoreActions={withMoreActions!}
        openUserProfile={openUserProfile!}
        onChangeAdmin={onChangeAdmin!}
        onRemove={onRemove!}
        onViewAnswers={onViewAnswers!}
      />
    );
  };

  return <ul className={className}>{members.map(renderMember)}</ul>;
};
