import {
  ISettingsParam,
  ISettingsValue,
} from '@wix/yoshi-flow-editor/tpa-settings';

// @ts-expect-error
import { GALLERY_CONSTS } from 'pro-gallery-lib'; // TODO: This changes from time to time, not a reliable source

import {
  MediaImagesPerRow,
  MediaLayout,
} from '../../Settings/settingsConstants';

export const MediaLayoutMap = {
  [MediaLayout.grid]: GALLERY_CONSTS.layout.GRID,
  [MediaLayout.collage]: GALLERY_CONSTS.layout.COLLAGE,
  [MediaLayout.masonry]: GALLERY_CONSTS.layout.MASONRY,
};
// TODO: where this comes from?
export interface GalleryStyles {
  enableInfiniteScroll: boolean;
  allowDescriptionExpand: boolean;
  imageMargin: number;
  galleryLayout: number;
  imageLoadingMode: any;
  floatingImages: number;
  gallerySize: number;
  videoLoop: boolean;
  numberOfImagesPerRow: number;
  hoveringBehaviour: any;
  loadMoreAmount: any;
  allowDescription: boolean;
  cubeRatio: number;
  imageHoverAnimation: any;
  gridStyle: number;
  groupTypes: string;
  itemClick: any;
  galleryMargin: number;
  allowTitle: boolean;
  gallerySizeType: any;
  titlePlacement: any;
  cubeType: any;
  bgColorExpand: { value: string };
  cubeImages: boolean;
  actionsColorExpand: { value: string };
  overlayAnimation: any;
  videoPlay: any;
  scrollAnimation: any;
}

export interface AppSettings {
  layoutSpacing: ISettingsValue<ISettingsParam<number>>;
  mobileLayoutSpacing: ISettingsValue<ISettingsParam<number>>;
  mediaLayout: ISettingsValue<ISettingsParam<MediaLayout>>;
  imagesPerRow: ISettingsValue<ISettingsParam<MediaImagesPerRow>>;
  mobileMediaLayout: ISettingsValue<ISettingsParam<MediaLayout>>;
}

export function getGalleryStyles(
  appSettings: AppSettings,
  colors: { bgColorExpand: string; actionsColorExpand: string },
  mobile: boolean,
): GalleryStyles {
  const {
    layoutSpacing,
    mobileLayoutSpacing,
    mediaLayout,
    imagesPerRow,
    mobileMediaLayout,
  } = appSettings;

  const { bgColorExpand, actionsColorExpand } = colors;
  try {
    const styles: GalleryStyles = {
      titlePlacement: GALLERY_CONSTS.placements.SHOW_ON_HOVER,
      allowTitle: true,
      allowDescription: true,
      allowDescriptionExpand: false,
      itemClick: GALLERY_CONSTS.itemClick.EXPAND,

      enableInfiniteScroll: true,
      galleryLayout: mobile
        ? MediaLayoutMap[mobileMediaLayout]
        : MediaLayoutMap[mediaLayout],
      gallerySizeType: GALLERY_CONSTS.gallerySizeType.SMART,
      gridStyle: mobile ? 0 : 1,
      floatingImages: 0,
      numberOfImagesPerRow: imagesPerRow,
      loadMoreAmount: GALLERY_CONSTS.loadMoreAmount.PARTIAL,

      gallerySize: mobile ? 0 : 30,
      galleryMargin: 0,
      imageMargin: mobile ? mobileLayoutSpacing : layoutSpacing,
      cubeType: GALLERY_CONSTS.cubeType.CROP,

      cubeRatio: 1,
      cubeImages: true,
      videoLoop: true,
      videoPlay: GALLERY_CONSTS.videoPlay.HOVER,
      imageLoadingMode: GALLERY_CONSTS.loadingMode.COLOR,

      hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.APPEARS,
      scrollAnimation: GALLERY_CONSTS.scrollAnimations.FADE_IN,
      overlayAnimation: GALLERY_CONSTS.overlayAnimations.FADE_IN,
      imageHoverAnimation: GALLERY_CONSTS.imageHoverAnimations.NO_EFFECT,

      bgColorExpand: {
        value: bgColorExpand,
      },
      actionsColorExpand: {
        value: actionsColorExpand,
      },
      groupTypes: mobile ? '0' : '3',
    };
    return styles;
  } catch (e) {
    console.log('[getGalleryStyles] Error');
  }
  return {} as any;
}
