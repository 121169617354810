import { IGroupActions } from './IGroupActions';
import React from 'react';
import { GroupActionsConsumer } from './GroupActionsContext';

export interface WithGroupActionProps extends IGroupActions {}

export const WithGroupActions =
  <P extends WithGroupActionProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof WithGroupActionProps>> =>
  (props) =>
    (
      <GroupActionsConsumer>
        {(contextValue) => (
          <WrappedComponent {...props} {...(contextValue as any)} />
        )}
      </GroupActionsConsumer>
    );
