import React from 'react';
import { GroupContext } from '../../../contexts/Group/GroupContext';
// TODO: [YO] fix types
export function usePolls(props: any) {
  const { handleUserValidationRequest } = props;

  const { members } = React.useContext(GroupContext);

  return {
    getSiteMembers: () => members,
    validateUser: handleUserValidationRequest ? validateUser : null,
  };

  function validateUser() {
    return new Promise(handleUserValidationRequest);
  }
}
