import React from 'react';
// WIX-UI-TPA
import { Grid } from 'wix-ui-tpa/Grid';
// API
import {
  canSeeFeed,
  canWritePost,
} from '@wix/social-groups-api/dist/src/model/Member/permissions';
import {
  FeedApiTypes,
  ReactionsApiTypes,
  SocialApiTypes,
} from '@wix/social-groups-api/dist/src/types';

import { About } from './About';
import { Feed, FeedProps } from './Feed';
import { MembersWidget } from './MembersWidget';
import { NewPost } from './NewPost';

// STYLES
import { st, classes } from './Discussion.st.css';

import { Navigation } from './Filters';
import { FeedTopicsWidget } from './FeedTopics';
import { EventsWidget } from '../Events/EventsWidget';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import { MembershipChangeAction } from '../../types/MembershipAction';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import { DETAILS_TITLE } from '../Details/dataHooks';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { UIError } from '../Error/UIError';
import { ErrorOrigin } from '../../controllers/errorHandler/IErrorEvent';
import { NewPostModal } from '../Modals/NewPostModal/NewPostModal';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { compose } from '../../../../common/utils/compose';
import { withMembershipChangeAction } from '../../contexts/Membership/withMembershipChangeAction';
import { EFilterKeys } from '../../types/EFilterKeys';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../Settings/settingsParams';
import {
  groupCreatePostClick,
  groupFeedTopicsAddTopicToPost,
  groupFeedView,
} from '@wix/bi-logger-groups/v2';
import { PaginationSeo } from './NextPage/PaginationSeo';
import {
  deepLinkPresentInQuery,
  DeepLinks,
  removeDeepLinkFromUrl,
} from '../../../../common/deeplinks/deeplinks';
import { FEED } from './dataHooks';

export interface DiscussionProps {
  feedItemId?: string;
  forceCreatePost?: boolean;

  resetForceCreatePost?(): void;
  discussionUrl?: string;
}

interface DiscussionState {
  isWritePostModalOpened: boolean;
  renderPostModal: boolean;
  isFeedItemCreating: boolean;
  draft: RawDraftContentState<any>;
}

type DiscussionComponentProps = WithTranslation &
  DiscussionProps &
  WithGroupProps &
  WithTpaComponentsConfigProps &
  WithSettingsProps &
  MembershipChangeAction &
  InjectedBiLoggerProps;

export class DiscussionComponent extends React.Component<
  DiscussionComponentProps,
  DiscussionState
> {
  readonly state: DiscussionState = {
    renderPostModal: false,
    isWritePostModalOpened: false,
    isFeedItemCreating: false,
    draft: null as any,
  };

  static getDerivedStateFromProps(
    nextProps: DiscussionComponentProps,
    prevState: DiscussionState,
  ): Partial<DiscussionState> {
    const { isFeedItemCreating } = nextProps.feed;
    const nextState: Partial<DiscussionState> = {
      isFeedItemCreating,
    };
    if (isFeedItemCreating === false && prevState.isFeedItemCreating === true) {
      nextState.isWritePostModalOpened = false;
    }
    return nextState;
  }

  componentDidUpdate(props: DiscussionComponentProps) {
    const isLoading = this.props.feed.feedLoading;

    // if previously was loading
    if (!isLoading && props.feed.feedLoading) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const $title =
      document && document.querySelector(`[data-hook="${DETAILS_TITLE}"]`);

    if (!$title) {
      return;
    }

    Promise.resolve().then(() => {
      requestAnimationFrame(() => {
        $title.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    });
  }

  componentDidMount(): void {
    const { forceCreatePost, resetForceCreatePost, bi } = this.props;
    if (forceCreatePost && resetForceCreatePost) {
      resetForceCreatePost();
    }

    bi.report(
      groupFeedView({
        clubId: this.props.group.groupId!,
      }),
    );
  }

  getFeedEntity = (
    content: RawDraftContentState<any>,
    topicId?: string,
  ): FeedApiTypes.FeedItemEntity => {
    return {
      body: {
        content: JSON.stringify(content),
        contentType: SocialApiTypes.ContentType.DRAFTJS,
      },
      topics: topicId ? [topicId] : [],
    };
  };

  reactFeedItem = (
    feedItemId: string,
    reaction: ReactionsApiTypes.Reaction,
  ) => {
    this.props.feed.reactFeedItem(feedItemId, reaction);
  };
  // TODO: is it the same interface? if not - why not?

  unreactFeedItem = (feedItemId: string, reactionCode: string) => {
    this.props.feed.unreactFeedItem(feedItemId, reactionCode);
  };

  handleCreatePost = (content: any, topicId?: string) => {
    const { feed, bi, group } = this.props;
    feed.createFeedItem(this.getFeedEntity(content, topicId));
    this.removeCreateNewPostDeepLink();

    if (topicId) {
      bi.report(
        groupFeedTopicsAddTopicToPost({
          groupId: group.groupId!,
          userEntry: BIUserEntry.SITE,
          origin: 'post_creation',
          topicName: topicId,
        }),
      );
    }
  };

  handleUpdatePost = (
    feedItemId: string,
    content: RawDraftContentState<any>,
    topicId?: string,
  ) =>
    this.props.feed.updateFeedItem(
      feedItemId,
      this.getFeedEntity(content, topicId),
    );

  handleDeletePost = (feedItemId: string) =>
    this.props.feed.deleteFeedItem(feedItemId);

  handlePinPost = (feedItemId: string) =>
    this.props.feed.pinFeedItem(feedItemId);

  handleUnpinPost = (feedItemId: string) =>
    this.props.feed.unpinFeedItem(feedItemId);

  handleFollowPost = (feedItemId: string) =>
    this.props.feed.followFeedItem(feedItemId);

  handleUnfollowPost = (feedItemId: string) =>
    this.props.feed.unfollowFeedItem(feedItemId);

  openNewPostModal = () => this.toggleNewPostModal(true);

  toggleNewPostModal = (isWritePostModalOpened: boolean) => {
    return this.setState({ isWritePostModalOpened, renderPostModal: true });
  };

  render() {
    const {
      group,
      feedItemId,
      mobile,
      discussionUrl,
      forceCreatePost,
      settings,
      feed,
      apps,
      isGroupOwner,
    } = this.props;
    const { draft } = this.state;
    const layoutSpacingKey = getSettingsKeyFor('layoutSpacing', mobile!);

    if (forceCreatePost) {
      this.maybeWritePost();
    }

    const layoutSpacing = settings.get(settingsParams[layoutSpacingKey]);
    return (
      <UIError origin={ErrorOrigin.Feed} isOwner={isGroupOwner!}>
        <link
          rel="canonical"
          href={feedItemId ? `${discussionUrl}/${feedItemId}` : discussionUrl}
        />
        <Grid
          maxColumns={mobile ? 1 : 3}
          columnGap={layoutSpacing}
          className={st(classes.root, { mobile } as any)}
        >
          <Grid.Item colSpan={mobile ? 1 : 2}>
            <div className={classes.container}>
              <Navigation />
              {feed.feedFilters && feed.feedFilters.feedItemId ? null : (
                <>
                  <NewPost
                    onClick={() => this.maybeWritePost('top_rce_area')}
                    draft={draft}
                  />
                  {mobile ? <FeedTopicsWidget /> : null}
                </>
              )}
              {this.renderContent()}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={classes.container}>
              <About group={group} apps={apps} />
              <MembersWidget />
              {!mobile ? (
                <>
                  <FeedTopicsWidget />
                  <EventsWidget />
                </>
              ) : null}
            </div>
          </Grid.Item>
        </Grid>
        {this.renderNewPostModal()}
      </UIError>
    );
  }

  handleDraftSave = (draft: any) => {
    this.setState({ draft });
    this.removeCreateNewPostDeepLink();
  };

  isCreateNewPostDeepLinkPresent = () => {
    try {
      return deepLinkPresentInQuery(
        window.location.search,
        DeepLinks.createNewPost,
      );
    } catch (e) {
      console.error(
        '[Discussion] Failed to retrieve create new post deeplink from url',
      );
    }
  };

  removeCreateNewPostDeepLink = () => {
    try {
      const nextUrl = removeDeepLinkFromUrl(
        window.location,
        DeepLinks.createNewPost,
      );

      window.history.replaceState(null, '', nextUrl);
    } catch (e) {
      console.log('[Discussion.removeOpenNewPostModalFromLocation] Failed');
    }
  };

  private readonly maybeWritePost = (biOrigin?: string) => {
    const { group, isLoggedIn, promptLogin, openJoinDialog, bi } = this.props;
    if (biOrigin) {
      bi.report(
        groupCreatePostClick({
          groupId: group.groupId,
          origin: biOrigin,
        } as any),
      );
    }
    // logged in?
    if (!isLoggedIn) {
      return promptLogin();
    }
    // has member permissions
    if (canWritePost(group)) {
      this.openNewPostModal();
    } else {
      openJoinDialog();
    }
  };

  private renderNewPostModal() {
    const {
      feed: { feedFilters },
    } = this.props;
    const { isWritePostModalOpened, isFeedItemCreating, draft } = this.state;

    const isNewPostModalOpen =
      isWritePostModalOpened || this.isCreateNewPostDeepLinkPresent();

    return (
      isNewPostModalOpen && (
        <NewPostModal
          initialContentState={draft}
          isPostPublishing={isFeedItemCreating}
          isOpen={isNewPostModalOpen}
          onVisibilityChange={this.toggleNewPostModal}
          onSubmit={this.handleCreatePost}
          onSaveDraft={this.handleDraftSave}
          topicId={feedFilters[EFilterKeys.TOPICS]}
        />
      )
    );
  }

  private renderContent() {
    const { group } = this.props;
    if (canSeeFeed(group)) {
      return this.renderFeed();
    }
    return null;
  }

  private renderFeed() {
    const { feed, t, discussionUrl } = this.props;
    // TODO: refactor for better debugging
    const postActions = {
      onDeletePostClick: this.handleDeletePost,
      onPinPostClick: this.handlePinPost,
      onUnpinPostClick: this.handleUnpinPost,
      onFollowPostClick: this.handleFollowPost,
      onUnfollowPostClick: this.handleUnfollowPost,
      onUpdatePostClick: this.handleUpdatePost,
      react: this.reactFeedItem,
      unreact: this.unreactFeedItem,
    };

    if (feed.feedLoading) {
      return <Spinner offset="L" label={t('groups-web.discussion.loading')} />;
    }
    if (!feed.feedItems) {
      // TODO: smth went wrong
      return null;
    }
    // TODO:why do we need this?
    const feedItems = feed.feedItems.filter(
      (
        feedItem, // activity post
      ) =>
        feedItem.activity || // user's post
        feedItem.entity?.body.contentType ===
          SocialApiTypes.ContentType.DRAFTJS,
    );
    return (
      <section className="feed" key="feed" data-hook={FEED}>
        {feed.cursor ? (
          <PaginationSeo
            url={discussionUrl!}
            query={{ cursor: feed.cursor, prevCursor: feed.prevCursor! }}
          />
        ) : null}
        <Feed
          {...(postActions as FeedProps)}
          hasMore={!!feed.cursor}
          fetchMore={feed.fetchMore}
          feedItems={feedItems}
          onCreatePostClick={() => this.maybeWritePost('discussion_tab_btn')}
          contextToken={feed.contextToken!}
        />
      </section>
    );
  }
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withTpaComponentsConfig,
  withSettings,
  withMembershipChangeAction,
  withBi,
);

export const Discussion = enhance(
  DiscussionComponent,
) as React.ComponentType<DiscussionProps>;

export default Discussion;
