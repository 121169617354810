import React from 'react';
import { Toast, TOAST_SKIN } from 'wix-ui-tpa/Toast';
import {
  AppToast as AppToastType,
  AppToastsProps,
  AppToastTypes,
} from '@wix/social-groups-api';

import { st, classes /* ... */ } from './AppToast.st.css';

const messagesKey: { [key in AppToastTypes]: string } = {
  [AppToastTypes.GROUP_CREATED]: 'groups-web.toast.group-created',
  [AppToastTypes.GROUP_CREATED_IN_PENDING_STATE]:
    'groups-web.toast.group-pending',
  [AppToastTypes.GROUP_REQUEST_TO_JOIN]:
    'groups-web.toast.group-request-to-join',
  [AppToastTypes.GROUP_LEFT]: 'groups-web.toast.left-group',
  [AppToastTypes.GROUP_JOINED]: 'groups-web.toast.group-joined',
  [AppToastTypes.GROUP_APPROVED]: 'groups-web.toast.group-approved',
  [AppToastTypes.GROUP_DECLINED]: 'groups-web.toast.group-declined',
  [AppToastTypes.GROUP_CANCELLED]: 'groups-web.toast.group-cancelled',
  [AppToastTypes.GROUP_DELETED]: 'groups-web.group.actions.delete.success',
  [AppToastTypes.MEMBERS_ADDED]: 'groups-web.toast.members-added',
  [AppToastTypes.GROUP_LOGO_UPDATED]: 'groups-web.toast.group-logo-updated'
};

const TOAST_ACKNOWLEDGE_TIMEOUT = 5000;

interface AppToastProps {
  t: any; // TODO: [YO]
  toast: AppToastType;
  acknowledge: AppToastsProps['acknowledge'];
  className?: string;
}

export class AppToast extends React.Component<AppToastProps> {
  private acknowledgeTimeout!: number;

  componentDidMount(): void {
    const { toast } = this.props;
    const time = TOAST_ACKNOWLEDGE_TIMEOUT - (Date.now() - toast.at);
    if (time > 0) {
      this.acknowledgeTimeout = window.setTimeout(this.closeToast, time);
    } else {
      this.closeToast();
    }
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.acknowledgeTimeout);
  }

  render() {
    const { toast, t } = this.props;
    return (
      <Toast
        className={st(classes.root, {}, this.props.className)}
        skin={TOAST_SKIN.success}
        shouldShowCloseButton
        onClose={this.closeToast}
      >
        {t(messagesKey[toast.type], toast.options)}
      </Toast>
    );
  }

  private readonly closeToast = () => {
    this.props.acknowledge(this.props.toast.id);
  };
}
