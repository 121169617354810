import React from 'react';
import { WixRicosViewer } from '@wix/ricos-viewer';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentViewerProps } from './types';

import './ContentEditor.global.css';
import { st, classes } from './ricos.st.css';
import { useHelpers } from './hooks/useHelpers';

import { createPreview } from './transformations';
import { useRicosTheme } from './useTheme';
import {
  useTranslation,
  useExperiments,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import { AppDataContext } from '../../contexts/AppData/AppData';
import { PluginTypeParser } from '../../../../common/ContentEditor/plugins/plugin-type-parser/PluginTypeParser';
import { HostContext } from '../../contexts/AppSettings/HostContext';

export const _RichContentViewer: React.FC<
  Omit<RichContentViewerProps, 'biSettings' | 'instance'>
> = (props) => {
  const {
    preset,
    usage,
    className,
    handleUserValidationRequest,
    disablePreview,
  } = props;
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const content = props.content as RawDraftContentState<any>;

  const { instance } = React.useContext(AppDataContext);
  const host = React.useContext(HostContext);
  const { language } = useEnvironment();
  const helpers = useHelpers({
    handleUserValidationRequest,
  });
  const pluginSelector = viewerPluginSelector(preset!, helpers);
  const [plugins, setPlugins] = React.useState(pluginSelector.plugins);
  const ricosThemeProps = useRicosTheme(host!);

  const { pluginTypes, hasPolls } = React.useMemo(() => {
    const pluginTypeParser: PluginTypeParser = new PluginTypeParser();
    return {
      pluginTypes: pluginTypeParser.getContentTypes(content),
      hasPolls: pluginTypeParser.hasPolls(content),
    };
  }, [content]);

  const enablePreview =
    !(hasPolls || disablePreview) &&
    experiments.enabled('specs.groups.RicosPreview');

  const preview = React.useMemo(
    () => (enablePreview ? createPreview(t) : null),
    [enablePreview],
  );

  React.useEffect(() => {
    if (pluginTypes.length) {
      pluginSelector
        .loadPlugins(pluginTypes)
        .then((_plugins) => {
          if (_plugins) {
            setPlugins([]); // have no idea why but it doesn't work without it
            setPlugins(_plugins);
          }
        })
        .catch((e) => {
          console.log('[RichContentViewer] Error Loading Plugins');
          // TODO: report sentry
        });
    }
  }, [JSON.stringify(pluginTypes)]);

  return (
    <div
      className={st(classes.root, { mobile: props.isMobile } as any, className)}
    >
      <WixRicosViewer
        {...ricosThemeProps}
        {...props}
        key={plugins.length}
        instance={instance}
        plugins={plugins}
        locale={language}
        preview={preview!}
        linkSettings={{ relValue: 'nofollow ugc', anchorTarget: '_blank' }}
        wixExperiments={experiments}
        biSettings={{
          consumer: 'Groups',
          platform: 'Livesite',
          usage,
        }}
      />
    </div>
  );
};

_RichContentViewer.defaultProps = {
  preset: PRESETS.POST,
  disablePreview: false,
};

export const RichContentViewer = _RichContentViewer;
