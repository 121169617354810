import React from 'react';

import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
// API
import {
  isProfilePublic,
  Anonymous,
} from '@wix/social-groups-api/dist/src/model/Member/Member';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { isJoined } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupControllerProps } from '../controllers/group/GroupControllerProps';
import { FeedControllerProps } from '../types/FeedControllerProps';
import { MembersControllerProps } from '../controllers/members/MembersControllerProps';
import { MemberInvitesControllerProps } from '../../../common/controllers/member-invites/MemberInvitesControllerProps';
import { WithAppToastsProps } from '../../../common/types/withAppToastsProps';
import { MainControllerProps } from '../controllers/main/MainControllerProps';
import { ActivityControllerProps } from '../controllers/activity/ActivityControllerProps';
import { NotificationSettings } from '../controllers/notifications/NotificationsControllerProps';
import { AppData } from '../contexts/AppData/IAppData';
import { ErrorHandlerControllerProps } from '../controllers/errorHandler/ErrorHandlerControllerProps';
import { EventsControllerProps } from '../controllers/events/EventsControllerProps';
import { ICommentsProps } from '../controllers/comments/ICommentsProps';
import { MediaControllerProps } from '../controllers/media/MediaControllerProps';
import { ConsoleLogger } from '../../../common/loggers/ConsoleLogger';
import { Tab } from '../../../common/controllers/group-url/Tab';
import { LogLevel } from '../../../common/loggers/Logger';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { isMobileByFormFactor } from '../../../common/utils/utils';

import { DATA_HOOKS } from './dataHooks';
import { HostContext } from '../contexts/AppSettings/HostContext';
import { LoggersContext } from '../contexts/logger/Loggers';
import { ProfilePrivacyDialog } from '../../../common/components/PrivacyDialog/ProfilePrivacyDialog';
import { OOIHost } from '../../../common/components/OOIComponent/OOIHost';
import { LeaveGroupDialog } from './Dialogs/LeaveGroupDialog/LeaveGroupDialog';
import { WithdrawJoinRequestDialog } from './Dialogs/WithdrawJoinRequest/WithdrawJoinRequest';
import { JoinDialog } from './Dialogs/JoinDialog/JoinDialog';
import { MembershipQuestionsModal } from '../../../common/components/MembershipQuestionsModal/MembershipQuestionsModal';
import { groupNotFound } from '../controllers/errorHandler/errorEvent';
import { ErrorOrigin } from '../controllers/errorHandler/IErrorEvent';
import { GroupNotFound } from './GroupNotFound/GroupNotFound';
import { ErrorHandlerContext } from '../contexts/ErrorHandler/ErrorHandlerContext';
import { AppDataContext } from '../contexts/AppData/AppData';
import { GroupContextProvider } from '../contexts/Group/GroupContext';
import { GroupActionsProvider } from '../contexts/GroupActions/GroupActionsContext';
import { SiteMembersContext } from '../contexts/SiteMembers/SiteMembers';
import { AppToastsProvider } from '../../../common/components/AppToats/AppToastsContext';
import { ActivityContext } from '../contexts/Activity/Activity';
import { NotificationSettingsContext } from '../contexts/NotificationSettings/NotificationSettings';
import { MemberInvitesProvider } from '../../../common/context/member-invites/MemberInvitesContext';
import { ProfileContext } from '../contexts/Profile/Profile';
import { EventsContext } from '../contexts/events/EventsContext';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { Host } from '@wix/comments-ooi-client/dist/types/common/platform-types';
import { Group } from './Group/Group';
import {
  InjectedBiLoggerProps,
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/yoshi-flow-editor';
import {
  groupsRequestJoinAGroup,
  groupActionClick,
} from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from '../../../common/bi-logger/types';
import { ThemeProvider } from '../../../common/context/theme';

enum OpenedDialog {
  WITHDRAW_JOIN_REQUEST = 'cancel join request',
  LEAVE_GROUP = 'leave group',
  JOIN_DIALOG = 'join dialog',
  PRIVACY_DIALOG = 'change profile privacy',
  QUESTIONS = 'questions',
}

export interface GroupWidgetState {
  error: null | string;
  openDialog: OpenedDialog;
}

export interface GroupWidgetProps
  extends GroupControllerProps,
    FeedControllerProps,
    MembersControllerProps,
    MemberInvitesControllerProps,
    WithAppToastsProps,
    MainControllerProps,
    ActivityControllerProps,
    NotificationSettings,
    AppData,
    InjectedBiLoggerProps,
    InjectedExperimentsProps,
    ErrorHandlerControllerProps,
    EventsControllerProps,
    MediaControllerProps,
    ICommentsProps {}

class GroupWidgetComponent extends React.Component<
  GroupWidgetProps,
  GroupWidgetState
> {
  static displayName = 'GroupWidget';
  readonly state: GroupWidgetState = {
    error: null,
    openDialog: null as any,
  };

  constructor(props: GroupWidgetProps, context: any) {
    super(props, context);
    if (typeof window !== 'undefined') {
      ConsoleLogger.setModeFromLocation();
      window.scrollTo(0, 0);
    }
  }

  componentDidMount(): void {
    this.reportAppLoaded();
  }

  componentDidUpdate(
    prevProps: Readonly<GroupWidgetProps>,
    prevState: Readonly<GroupWidgetState>,
  ) {
    if (!prevProps.reportAppLoaded && this.props.reportAppLoaded) {
      this.reportAppLoaded();
    }
  }

  private reportAppLoaded() {
    if (!this.props.reportAppLoaded) {
      return;
    }
    try {
      this.props.host.registerToComponentDidLayout(this.props.reportAppLoaded);
    } catch (e) {
      console.log('Error in GroupWidget.reportAppLoaded');
    }
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
  }

  static getDerivedStateFromError(error: any) {
    return {
      error,
    };
  }

  static defaultProps: Partial<GroupWidgetProps> = {
    isLoggedIn: false,
    promptLogin() {},
    actions: {} as any,
    activeTab: Tab.DISCUSSION,
    currentMember: Anonymous,
    // experiments: {},
    group: GroupWrapper.createEmpty(),
    loading: false,
    locale: '',
    logLevel: LogLevel.NONE,
    memberInvitesLink: '',
    members: [],
    membersActions: {} as any,
    siteMembers: [],
    siteMembersMap: {},
    style: {} as any,
    uploadedRegistry: [],
    externalVideosMetadataRegistry: [],
    changeTab(): void {},
    commentFeedItem(): void {},
    createFeedItem(): void {},
    updateFeedItem(): void {},
    deleteFeedItem(): void {},
    pinFeedItem(): void {},
    unpinFeedItem(): void {},
    followFeedItem(): void {},
    unfollowFeedItem(): void {},
    updateProgress: UpdateProgress.STALE,
  };

  render() {
    const { host, isRTL, experiments } = this.props;

    const { style: styles, formFactor } = host;

    const isMobile = isMobileByFormFactor(formFactor);

    if (this.state.error !== null) {
      return (
        <>
          <h1>Ooops!</h1>
          <div>{this.state.error}</div>
        </>
      );
    }

    const style = this.getSiteRootWidth() as any;

    const dir = isRTL ? 'rtl' : null;

    return (
      <div data-hook={DATA_HOOKS.root} style={style} dir={dir!}>
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <HostContext.Provider value={host}>
            <LoggersContext.Provider value={{ logger: ConsoleLogger }}>
              <ThemeProvider
                value={{
                  forceBlackAndWhite: experiments.enabled(
                    'specs.groups.ForceBlackAndWhite',
                  ),
                }}
              >
                {this.renderGroup()}
              </ThemeProvider>
            </LoggersContext.Provider>
          </HostContext.Provider>
        </TPAComponentsProvider>
      </div>
    );
  }

  private renderGroup() {
    const { errorHandlers, errorEvents, host, ...rest } = this.props;
    if (errorEvents && groupNotFound(errorEvents[ErrorOrigin.Group])) {
      const height = host.dimensions.height ? host.dimensions.height : 'auto';
      return (
        <div style={{ height }}>
          <GroupNotFound
            goToGroupList={() =>
              errorHandlers.actionByError(errorEvents[ErrorOrigin.Group])
            }
          />
        </div>
      );
    }
    const {
      actions,
      style,
      isRTL,
      isEditor,
      viewMode,
      cursor,
      prevCursor,
      contextToken,
      fetchMore,
      activeTab,
      activeButton,
      activities,
      activityActions,
      apps,
      changeTab,
      commentFeedItem,
      createFeedItem,
      currentMember,
      pendingMembers,
      currentSiteMember,
      deleteFeedItem,
      feedItemId,
      feedItems,
      feedTopics,
      feedFilters,
      mediaItems,
      followFeedItem,
      followingMembers,
      group,
      rules,
      isFeedItemCreating,
      isLoggedIn,
      loadMemberInvitesLink,
      feedLoading,
      memberInvitesLink,
      members,
      membersActions,
      membersQueryResponse,
      newMembers,
      badges,
      membersBadgeIds,
      notificationActions,
      notificationSettings,
      pinFeedItem,
      promptLogin,
      reactFeedItem,
      siteMembers,
      siteMembersMap,
      nonGroupMembersCount,
      toasts,
      createPostTopic,
      applyFeedFilters,
      unfollowFeedItem,
      unpinFeedItem,
      unreactFeedItem,
      updateFeedItem,
      updateProgress,
      uploadedRegistry,
      externalVideosMetadataRegistry,
      instance,
      membersUpdate,
      questions,
      questionsAnswers,
      language,
      locale,
      instanceId,
      requestState,
      eventsContext,
      mediaContext,
      siteNavigation,
      navigateToLink,
      isGroupOwner,
      tabsUrls,
      ...commentsProps
    } = rest;
    return (
      <ErrorHandlerContext.Provider value={{ errorEvents, errorHandlers }}>
        <AppDataContext.Provider
          value={{
            instance,
            locale,
            instanceId,
            activeButton,
          }}
        >
          <GroupContextProvider
            value={{
              group,
              members,
              rules,
              currentMember,
              uploadedRegistry,
              externalVideosMetadataRegistry,
              media: mediaContext,
              feed: {
                createPostTopic,
                applyFeedFilters,
                feedLoading,
                contextToken,
                cursor,
                prevCursor,
                fetchMore,
                isFeedItemCreating,
                feedItems,
                feedTopics,
                feedFilters,
                mediaItems,
                deleteFeedItem,
                createFeedItem,
                updateFeedItem,
                commentFeedItem,
                pinFeedItem,
                unpinFeedItem,
                followFeedItem,
                unfollowFeedItem,
                reactFeedItem,
                unreactFeedItem,
              },
              promptLogin,
              isLoggedIn,
              updateProgress,
              apps,
              questions,
              isGroupOwner,
            }}
          >
            <GroupActionsProvider
              value={{
                ...actions,
                changeMembership: this.changeMembership,
                openJoinDialog: this.openJoinDialog,
              }}
            >
              <SiteMembersContext.Provider
                value={{
                  currentSiteMember,
                  followingMembers,
                  membersActions,
                  membersQueryResponse,
                  newMembers,
                  pendingMembers,
                  siteMembers,
                  siteMembersMap,
                  badges,
                  membersBadgeIds,
                  membersUpdate,
                  nonGroupMembersCount,
                  questionsAnswers,
                  requestState,
                }}
              >
                <AppToastsProvider value={toasts}>
                  <ActivityContext.Provider
                    value={{ activities, activityActions }}
                  >
                    <NotificationSettingsContext.Provider
                      value={{
                        notificationActions,
                        notificationSettings,
                      }}
                    >
                      <MemberInvitesProvider
                        value={{ memberInvitesLink, loadMemberInvitesLink }}
                      >
                        <ProfileContext.Provider
                          value={{
                            isProfilePrivate: this.isProfilePrivate(),
                            openProfileDialog: this.openProfileDialog,
                            isProfileUpdating: this.isProfileUpdating(),
                          }}
                        >
                          <EventsContext.Provider value={eventsContext}>
                            <WixCommentsApiProvider
                              host={host as Host}
                              {...(commentsProps as any)}
                            >
                              <Group
                                feedItemId={feedItemId}
                                activeTab={activeTab}
                                changeTab={changeTab}
                                canSeeGroup={canSeeGroup(group)}
                                ready={!!group.groupId}
                                apps={apps}
                                group={group}
                                siteNavigation={siteNavigation}
                                navigateToLink={navigateToLink}
                                tabsUrls={tabsUrls!}
                                isRTL={isRTL!}
                                isEditor={isEditor}
                              />
                            </WixCommentsApiProvider>
                          </EventsContext.Provider>
                        </ProfileContext.Provider>
                        {this.renderWithdrawJoinRequestDialog()}
                        {this.renderLeaveDialog()}
                        {this.renderJoinDialog()}
                        {this.renderPrivacyDialog()}
                        {this.renderQuestions()}
                      </MemberInvitesProvider>
                    </NotificationSettingsContext.Provider>
                  </ActivityContext.Provider>
                </AppToastsProvider>
              </SiteMembersContext.Provider>
            </GroupActionsProvider>
          </GroupContextProvider>
        </AppDataContext.Provider>
      </ErrorHandlerContext.Provider>
    );
  }

  private isProfileUpdating() {
    const { membersUpdate, currentSiteMember } = this.props;
    return (
      membersUpdate &&
      currentSiteMember &&
      membersUpdate.includes(currentSiteMember.id)
    );
  }

  private renderLeaveDialog() {
    const { group } = this.props;
    const groupWrapper = new GroupWrapper(group);

    return (
      <LeaveGroupDialog
        isOpen={this.isOpenDialog(OpenedDialog.LEAVE_GROUP)}
        onRequestClose={this.closeDialog}
        groupId={group.groupId!}
        groupTitle={groupWrapper.getTitle()}
        onLeaveGroup={this.handleLeaveGroupConfirm}
      />
    );
  }

  private renderWithdrawJoinRequestDialog() {
    return (
      <WithdrawJoinRequestDialog
        isOpen={this.isOpenDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST)}
        onRequestClose={this.closeDialog}
        onWithdrawJoinRequest={this.handleWithdrawJoinRequest}
      />
    );
  }

  private renderJoinDialog() {
    return (
      <JoinDialog
        isOpen={this.isJoinDialogOpen()}
        onRequestClose={this.refuseAndCloseDialog}
      />
    );
  }

  refuseAndCloseDialog = () => {
    if (this.props.refuseToJoin) {
      this.props.refuseToJoin();
    }
    this.closeDialog();
  };

  private isJoinDialogOpen() {
    if (this.props.requestToJoin && !this.isProfilePrivate()) {
      return true;
    }
    return this.isOpenDialog(OpenedDialog.JOIN_DIALOG);
  }

  private renderQuestions() {
    const { group, questions, actions, host } = this.props;
    const groupWrapper = new GroupWrapper(group);
    return (
      <MembershipQuestionsModal
        isOpen={!!questions && this.state.openDialog === OpenedDialog.QUESTIONS}
        onRequestClose={this.closeQuestions}
        questions={questions}
        groupName={groupWrapper.getTitle()}
        groupId={group.groupId!}
        onSubmit={(answers) => actions.submitAnswers(group, answers)}
        className={host && host.id}
      />
    );
  }

  private readonly closeQuestions = () => {
    const { actions } = this.props;
    actions.rejectAnswers();
    this.closeDialog();
  };

  private renderPrivacyDialog() {
    return (
      this.isProfilePrivate() && (
        <ProfilePrivacyDialog
          onRequestClose={this.refuseAndCloseDialog}
          onChangeProfile={this.makeProfilePublic}
          isOpen={this.isPrivacyDialogOpen()}
        />
      )
    );
  }

  private isPrivacyDialogOpen() {
    if (this.props.requestToJoin && this.isProfilePrivate()) {
      return true;
    }
    return this.isOpenDialog(OpenedDialog.PRIVACY_DIALOG);
  }

  private readonly changeMembership = (biOrigin: string) => {
    const { group } = this.props;

    switch (group.relationship) {
      case ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP:
      case ApiTypes.v1.RelationshipWithGroup.NONE:
        return this.handleJoinGroup(biOrigin);
      case ApiTypes.v1.RelationshipWithGroup.JOINED:
        return this.handleLeaveGroupClick(biOrigin);
      case ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL:
        return this.openWithdrawJoinRequestDialog();
      default:
        console.warn('Unknown group relationship');
    }
  };

  private isProfilePrivate(): boolean {
    const { currentSiteMember } = this.props;
    return currentSiteMember && !isProfilePublic(currentSiteMember);
  }

  private readonly makeProfilePublic = () => {
    try {
      this.props.membersActions.makeProfilePublic(
        this.props.currentSiteMember.id,
      );
    } catch (e) {
      console.log('Failed to make profile public');
    }
  };
  private readonly openProfileDialog = () =>
    this.openDialog(OpenedDialog.PRIVACY_DIALOG);
  private readonly openJoinDialog = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileDialog();
    }
    return this.openDialog(OpenedDialog.JOIN_DIALOG);
  };
  private readonly openLeaveDialog = () =>
    this.openDialog(OpenedDialog.LEAVE_GROUP);
  private readonly openWithdrawJoinRequestDialog = () =>
    this.openDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST);
  private readonly closeDialog = () =>
    this.setState({ openDialog: null as any });

  private readonly openDialog = (dialog: any) => {
    this.setState({ openDialog: dialog });
  };

  private isOpenDialog(dialog: OpenedDialog): boolean {
    return this.state.openDialog === dialog;
  }

  private readonly handleLeaveGroupConfirm = () => {
    this.props.actions.leaveGroup();
    this.closeDialog();
  };

  private readonly handleWithdrawJoinRequest = () => {
    this.props.actions.withdrawJoinRequest();
    this.closeDialog();
  };

  private readonly handleJoinGroup = (biOrigin: string) => {
    const { group, bi, actions } = this.props;
    if (!isJoined(group as any)) {
      bi.report(
        groupsRequestJoinAGroup({
          approvalStatus: group.approvalStatus,
          groupId: group.groupId,
          origin: biOrigin,
          type: group.settings!.privacyLevel,
        } as any),
      );
    }
    actions.joinGroup();
    this.openDialog(OpenedDialog.QUESTIONS);
  };

  private readonly handleLeaveGroupClick = (biOrigin: string) => {
    const { group, bi } = this.props;
    bi.report(
      groupActionClick({
        action: 'leave',
        group_id: group.groupId!,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
      }),
    );
    this.openLeaveDialog();
  };

  private getSiteRootWidth() {
    try {
      const host = new OOIHost(this.props.host);
      if (host.isPreview() && host.isMobile()) {
        const { width, left } = host.getDimensions();
        const w = width + left * 2;

        if (w) {
          return { '--siteRootWidth': `${w}px` };
        }
      }
    } catch (e) {}
    return;
  }
}

export const GroupWidget = withExperiments(
  GroupWidgetComponent,
) as React.ComponentType<GroupWidgetProps>;
