import { MAX_IMAGES, MAX_TEXT_LINES } from './constants';

export function fallback(t: any) {
  return {
    _if: (metadata: any) =>
      !metadata.allText.length &&
      !metadata.media.totalCount &&
      metadata.nonMediaPluginsCount,
    _then: (metadata: any, preview: any) =>
      preview
        .plain({
          block: {
            data: {},
            depth: 0,
            entityRanges: [],
            inlineStyleRanges: [],
            text: ' ',
            type: 'unstyled',
          },
        })
        .seeFullPost({
          label: t('groups-web.rce.preview.see-full-post.label'),
        }),
  };
}

export function textPreview(t: any) {
  return {
    _if: (metadata: any) => metadata.allText.length > 0,
    _then: (metadata: any, preview: any) => {
      const interaction = preview.plain(metadata.textFragments[0]).readMore({
        lines: MAX_TEXT_LINES,
        label: t('groups-web.rce.preview.see-more.label'),
      });

      if (metadata.nonMediaPluginsCount && !metadata.media.totalCount) {
        interaction.seeFullPost({
          label: t('groups-web.rce.preview.see-full-post.label'),
        });
      }

      return interaction;
    },
  };
}

export function mediaPreview(t: any) {
  return {
    _if: (metadata: any) => {
      if (metadata.media.totalCount > 1) {
        return true;
      }

      const [mediaItem] = metadata.media.galleryItems;

      return mediaItem && mediaItem.isGalleryItem;
    },
    _then: parse,
  };

  function parse(metadata: any, preview: any) {
    const { media } = metadata;

    const images = media.singleMediaItems
      .concat(media.galleryItems)
      .map((mediaItem: any) => {
        let url;
        let height;
        let width;

        switch (mediaItem.type) {
          case 'video':
            if (!mediaItem.url.thumbnail) {
              return null;
            }

            url = mediaItem.url.thumbnail.pathname;
            height = mediaItem.url.thumbnail.height;
            width = mediaItem.url.thumbnail.width;
            break;

          case 'giphy':
          case 'image':
          default:
            url = mediaItem.url;
            height = mediaItem.height;
            width = mediaItem.width;
            break;
        }

        return {
          url,
          width,
          height,
          type: 'image',
          isGalleryItem: true,
        };
      });

    const previewItems = images
      .slice(0, MAX_IMAGES)
      .filter((item: any) => !!item);

    const interaction = preview.gallery({
      mediaInfo: previewItems,
      scrollingElement: document.body,
      overrides: {
        styles: {
          galleryLayout: 2,
          numberOfImagesPerRow: MAX_IMAGES,
        },
      },
    });

    if (images.length > previewItems.length) {
      interaction.imageCounter({
        counter: images.length - previewItems.length,
      });
    }

    return interaction.seeFullPost({
      label: t('groups-web.rce.preview.see-full-post.label'),
    });
  }
}

function isSingleMediaItem(type: any) {
  return (metadata: any) => {
    if (metadata.media.totalCount !== 1) {
      return false;
    }

    const [mediaItem] = metadata.media.singleMediaItems;

    if (!mediaItem) {
      return false;
    }

    return mediaItem.type === type;
  };
}

export function imagePreview() {
  return {
    _if: isSingleMediaItem('image'),
    _then: (metadata: any, preview: any) =>
      preview.image({
        mediaInfo: metadata.media.singleMediaItems[0],
      }),
  };
}

export function videoPreview() {
  return {
    _if: isSingleMediaItem('video'),
    _then: (metadata: any, preview: any) =>
      preview.video({
        mediaInfo: metadata.media.singleMediaItems[0],
      }),
  };
}

export function giphyPreview() {
  return {
    _if: isSingleMediaItem('giphy'),
    _then: (metadata: any, preview: any) =>
      preview.giphy({
        mediaInfo: metadata.media.singleMediaItems[0],
      }),
  };
}
