import React from 'react';
import {
  MembershipAction,
  MembershipChangeAction,
} from '../../types/MembershipAction';
import { IGroupActions } from './IGroupActions';

export const aDefaultGroupActions: GroupActionsContextType = {
  deleteGroup() {},
  setEditMode() {},
  updateGroup() {},
  changeMembership(biOrigin: string) {},
  openJoinDialog() {},
  uploadFiles() {},
  getExternalVideoMetadata() {},
  changeTab() {},
  goToGroupList() {},
  inviteMembersByEmail(emails: string[]) {},
  configureApps() {},
  fetchGroupRules() {},
  saveMembershipQuestions() {
    return null as any;
  },
  getMembershipQuestions() {
    return null as any;
  },
};

export type GroupActionsContextType = Omit<
  IGroupActions,
  keyof MembershipAction
> &
  MembershipChangeAction;

export const GroupActionsContext =
  React.createContext<GroupActionsContextType>(aDefaultGroupActions);

GroupActionsContext.displayName = 'GroupActionsContext';

export const GroupActionsProvider = GroupActionsContext.Provider;
export const GroupActionsConsumer = GroupActionsContext.Consumer;
